<template>
    <div class="textFileCenter mt-2">
        <div class="signupBottomContent">By using the Tibura apps you agree to our Terms of use and accept how the
            data will be used as defined the <span @click="showPolicy()"
                style="text-decoration: underline;cursor: pointer;">Privacy Policy</span> and <span @click="showCookies()"
                style="text-decoration: underline;;cursor: pointer;">Cookie Policy</span>.</div>
    </div>
    <v-dialog persistent v-model="policyModel" width="600">
        <Privacy @close="closePolicy()"> </Privacy>
    </v-dialog>
    <v-dialog persistent v-model="cookieModel" width="600">
        <Cookie @close="closeCookies()"> </Cookie>
    </v-dialog>
</template>
<script>
import Privacy from './Privacy.vue'
import Cookie from './Cookie.vue'
export default {
    components: {
        Privacy,
        Cookie
    },
    data() {
        return {
            policyModel: false,
            cookieModel: false,
        }
    },
    methods: {
        showCookies() {
            this.cookieModel = true
        },
        showPolicy() {
            this.policyModel = true
        },
        closeCookies() {
            this.cookieModel = false
        },
        closePolicy() {
            this.policyModel = false
        }
    }
}
</script>