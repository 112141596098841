import ApiService from '@/api/service/ApiService.js';
import apiConfig from '../../config/config'
var $options = {};
var UserService = {
    authenticate: function($data) {
        return new Promise(function(resolve, reject) {
            $options.path = "authenticate"
            $options.baseUrl=apiConfig.gatewayUrl
            $options.method = "POST"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    updateAuthLog: function($data){
        return new Promise(function(resolve,reject){
            $options.path = "authLog"
            $options.baseUrl=apiConfig.gatewayUrl
            $options.method = "PUT"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });

    }
};
export default UserService;