<template>
  <div v-if="loading" class="loading-container">
    <v-progress-circular indeterminate></v-progress-circular>
  </div>
  <v-row v-else-if="!loading && (appData && appData.name) || url == '/resetPassword'" class="main">
    <v-col align="center">
      <Logo></Logo>
      <router-view />
    </v-col>
  </v-row>
  <div class="loading-container" v-else>
    <v-card>
      &nbsp;
      <v-img style="margin: 0 auto;" src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/logo/Tibura.png"
        width="100px"></v-img>
      <v-card-text class="text-red" style="font-weight: 700;">Request did not originate from an Authorized
        domain!</v-card-text>
    </v-card>
  </div>
</template>

<script>
import DynamodbModel from "@/api/service/Dynamodb/DynamodbService";
import Logo from '@/components/Logo.vue'
import { authStore } from '@/store';

export default {
  name: 'App',
  components: { Logo },
  data() {
    return {
      appData: null,
      loading: true,
      url: this.$route.path,
      loginData: {}
    }
  },
  async created() {
    this.loginData = JSON.parse(localStorage.getItem('login_details'))
    var $data = {}
    if (this.loginData && this.loginData.sessionId) {
      $data = {
        sessionId: this.loginData.sessionId,
        redirectUrl: this.loginData.redirectUrl
      }
    }
    else {
      $data = {
        sessionId: this.$route.query.sessionId,
        redirectUrl: this.$route.query.redirectUrl
      }
    }
    const queryParams = Object.keys($data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent($data[key])}`).join("&");
    const $response = await DynamodbModel.checkAuthLog(queryParams)
    if ($response.session_id) {
      let appId = this.loginData && this.loginData.appId ? this.loginData.appId : this.$route.query.appId
      let res = await DynamodbModel.AppDetails(appId);
      authStore.commit('setAppDetail', res)
      this.appData = res
      document.title = this.appData.product_family
      const myIcon = document.querySelector('#my-icon')
      myIcon.href = this.appData.favicon
      this.loading = false
    } else {
      this.loading = false;
    }
  }
}
</script>


<style lang="scss">
@import '../src/scss/style.scss';

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Set the height of the container to the full viewport height */
}
</style>
