import {createApp} from 'vue'
import App from './App.vue'
import './scss/style.scss'
import router from './router'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
  components,
  directives,
})
const app = createApp(App);
app.use(router).use(vuetify);
router.isReady().then(()=>{ app.mount('#app')})
