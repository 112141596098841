<template>
    <v-card class="card mt-5 pa-8">
        <v-card-title class="LoginTitle">Verify with OTP</v-card-title>
        <v-col class="pa-0 mt-3">
            <v-text-field placeholder="Your OTP" density="compact" variant="outlined"></v-text-field>
        </v-col>
        <v-col class="pa-0 mb-5" align="center">
            <v-btn color="indigo" block to="/verification" type="submit" size="large">Verify</v-btn>
        </v-col>
    </v-card>
    <Footer />
</template>
<script>
import Footer from './Footer.vue';
export default {
    components: {
        Footer
    },

}
</script>
<style lang="scss"></style>