<template>
  <v-form @submit.prevent="login" ref="form">
    <v-card class="card mt-5 pa-8">
      <v-card-title class="LoginTitle">Login to {{ appDetail.name }}</v-card-title>
      <v-col cols="12" class="pa-0">
        <v-text-field class="mt-4" density="compact" placeholder="Your Email" type="email"
          append-inner-icon="mdi-account-outline" color="red" autofocus :rules="[rules.userRule, rules.emailValidation]"
          v-model="email" variant="outlined">
        </v-text-field>
      </v-col>
      <v-col cols="12" class="pa-0 mt-2">
        <v-text-field density="compact" placeholder="Password" :rules="passwordRule" v-model="password" variant="outlined"
          v-bind:type="[showPassword ? 'text' : 'password']">
          <span class="visionIcon">
            <v-icon @click="showPassword = !showPassword">{{
              showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
            }}</v-icon>
          </span>
        </v-text-field>
      </v-col>
      <v-col class="pa-0" cols="12" align="center">
        <p style="color: red;">{{ message }}</p>
      </v-col>
      <v-col class="pa-0 mt-2" cols="12" align="center">
        <v-btn block color="indigo" type="submit" size="large">
          <p v-if="!showLoader">Login</p>
          <v-progress-circular v-else indeterminate></v-progress-circular>
        </v-btn>
      </v-col>

      <!-- <v-row no-gutters class="mt-3">
        <v-col><v-divider class="border-opacity-100 mt-3" color="grey-lighten-1"></v-divider></v-col>
        <v-col align="center">OR</v-col>
        <v-col><v-divider class="border-opacity-100 mt-3" color="grey-lighten-1"></v-divider></v-col>
      </v-row> -->
      <v-row no-gutters>
        <!-- <v-col align="center" class="mt-4">
          <v-btn block color="red" variant="outlined" size="large">
            <img class="mr-4" src="../assets/image/google.svg" alt="Google" />
            Sign in with Google</v-btn>
        </v-col>
        <v-col cols="12" align="center" class="mt-4">
          <v-btn block color="black" variant="outlined" size="large">
            <img class="pr-7" src="../assets/image/apple.svg" alt="Apple" />
            Sign in with Apple</v-btn>
        </v-col> -->
        <v-col cols="12" align="center" class="mt-2">
          <v-btn variant="plain" to="/forgetpassword">Forgot your password ?</v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-1">
        <v-col class="pt-1">Trouble Signing in</v-col>
        <v-col align="end">
          <v-btn color="indigo" variant="plain" to="/signup">Sign up</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
  <Footer />
</template>
  
<script>
import UserModel from "@/api/service/user/UserService";
import { authStore } from "@/store";
import Footer from './Footer.vue';
//import apiConfig from '@/api/config/config'
export default {
  components: {
    Footer
  },
  data() {
    return {
      showLoader: false,
      message: null,
      showPassword: false,
      password: "",
      emailValidation: "",
      passwordRule: [(value) => !!value || "Password is Required."],
      email: "",
      rules: {
        userRule: (value) => !!value || "Email is Required.",
      },

      appData: JSON.parse(localStorage.getItem("AppDetail")),
    };
  },
  computed: {
    appDetail() {
      return authStore.getters.getAppDetail;
    },
  },
  created() {
    if (this.$route.query.appId) {
      let data = {
        appId: this.$route.query.appId,
        sessionId: this.$route.query.sessionId,
        redirectUrl: this.$route.query.redirectUrl
      }
      localStorage.setItem('APP_ID', this.$route.query.appId)
      localStorage.setItem('login_details', JSON.stringify(data));
    }
  },
  methods: {
    async login() {
      let validate = await this.$refs.form.validate();
      if (validate.valid) {
        this.showLoader = true;
        let loginData = JSON.parse(localStorage.getItem('login_details'))
        await UserModel.authenticate({
          email: this.email,
          password: this.password,
          appId: loginData && loginData.appId ? loginData.appId : this.$route.query.appId
        }).then((response) => {
          UserModel.updateAuthLog({
            sessionId: loginData && loginData.sessionId ? loginData.sessionId : this.$route.query.sessionId,
            loginSuccess: true
          })
          this.showLoader = false;
          let $referrer = localStorage.getItem("ONEAPP_REFERRER");
          localStorage.removeItem('userEmail')
          localStorage.removeItem('login_details')
          localStorage.removeItem('APP_ID')
          localStorage.removeItem('ONEAPP_REFERRER')
          window.location = `${$referrer}login?token=${response.token}`;


        })
          .catch((err) => {
            this.showLoader = false;
            if (err.response.data.message) {
              this.message = "Invalid Email Or Password"
            }


          });
      }
    },
  },
};
</script>
<style lang="scss"></style>
