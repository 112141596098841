<template>
    <v-form @submit.prevent="resetPassword" ref="form">
        <v-card class="card mt-7 pa-8">
            <v-row no-gutters>
                <v-col cols="12" align="center">
                    <img src="../assets/image/forget.svg" alt="forget" />
                </v-col>
                <v-col cols="12" align="center" class="LoginTitle mt-3 pa-0">
                    Set new Password</v-col>
                <v-col cols="12" align="center" class="textcontent mt-3">No worries,we'll send you reset
                    instructions
                </v-col>
            </v-row>
            <v-col class="pa-0 mt-3">
                <v-text-field :rules="passwordRule" v-model="newPassword" placeholder="Password" density="compact"
                    variant="outlined" v-bind:type="[showPassword1 ? 'text' : 'password']">
                    <span class="visionIcon">
                        <v-icon @click="showPassword1 = !showPassword1">{{ showPassword1 ? 'mdi-eye-outline' :
                            'mdi-eye-off-outline' }}</v-icon>
                    </span>
                </v-text-field>
            </v-col>
            <v-col class="pa-0 mt-3">
                <v-text-field v-model="confirmPassword" placeholder="Confirm password" density="compact" variant="outlined"
                    v-bind:type="[showPassword ? 'text' : 'password']">
                    <span class="visionIcon">
                        <v-icon @click="showPassword = !showPassword">{{ showPassword ? 'mdi-eye-outline' :
                            'mdi-eye-off-outline' }}</v-icon>
                    </span>
                </v-text-field>
                <div v-if="passwordError" class="error">{{ message }}</div>
            </v-col>
            <v-col class="pa-0 mt-2" cols="12" align="center">
                <v-btn block @click="resetPassword()" color="indigo" size="large">Reset
                    Password</v-btn>
            </v-col>
            <v-col class="pa-0 mt-4" cols="12" align="center">
                <v-btn variant="plain" prepend-icon="mdi-arrow-left" type="submit" to="/">Back to log in</v-btn>
            </v-col>
        </v-card>
    </v-form>
    <Footer />
</template>

<script>
import Footer from './Footer.vue';
import ResetPasswordModel from '@/api/service/ResetPasswordService';
export default {
    components: {
        Footer
    },
    data() {
        return {
            showPassword: false,
            showPassword1: false,
            newPassword: "",
            message: null,
            passwordError: false,
            confirmPassword: "",
            passwordRule: [
                (value) => {
                    if (value) return true;
                    return "Password is required";
                },
                (value) => {
                    if (/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}/.test(value)) return true;
                    return "Password must contain at least lowercase letter, one number, a special character and one uppercase letter";
                },
            ],
        }
    },
    created() {
        let data = {
            appId: this.$route.query.appId,
            sessionId: this.$route.query.sessionId,
            redirectUrl: this.$route.query.redirectUrl
        }
        localStorage.setItem('APP_ID', this.$route.query.appId)
        localStorage.setItem('login_details', JSON.stringify(data));
    },
    methods: {
        async resetPassword() {
            if (this.passwordRule.every((rule) => rule(this.newPassword) === true)) {
                var params = this.$route.query
                if (this.newPassword == this.confirmPassword && this.newPassword != "" && this.confirmPassword != "") {
                    this.passwordError = false;
                    var res = await ResetPasswordModel.resetPassword({ uid: params.uid, newPassword: this.newPassword, appId: params.appId })
                    console.log(res)
                    this.$router.push({ path: '/passwordreset' })
                }
                else {
                    this.passwordError = true;
                    this.message = "Password does not match";
                }
            }
            else {
                this.passwordError = true;
                this.message = "Password does not meet the requirements";
            }
        }
    }
}
</script>

<style scoped>
.error {
    margin-bottom: 5px;
    color: red;
    font-family: sans-serif;
    font-size: 16px;
}
</style>