import ApiService from '@/api/service/ApiService.js';
import apiConfig from '../../config/config'
var $options = {};
var DynamodbService = {
    AppDetails: function (appId) {
        return new Promise(function (resolve, reject) {
            $options.path = "appDetail?APP_ID=" + appId
            $options.baseUrl = apiConfig.gatewayUrl
            $options.method = "get"
            ApiService.request($options)
                .then(response => {
                    console.log(response, "working3333")
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    checkAuthLog: function ($data) {
        return new Promise(function (resolve, reject) {
            $options.path = "authLog?" + $data
            $options.baseUrl = apiConfig.gatewayUrl
            $options.method = "get"
            ApiService.request($options)
                .then(response => {
                    console.log(response, "working3333")
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    createUser: function ($data) {
        return new Promise(function (resolve, reject) {
            $options.path = "createUser"
            $options.data = $data
            $options.baseUrl = apiConfig.gatewayUrl
            $options.method = "POST"
            console.log($options,"working222")
            ApiService.request($options)
                .then(response => {
                    console.log(response, "working3333")
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    }
};
export default DynamodbService;