<template>
    <v-card class="card mt-5 pa-4">
        <v-alert>
            <p>Seems like you have been using this account for other applications. You can use the same password and login
                to this account</p>
        </v-alert>
        <v-card-text>
            <v-form @submit.prevent="save" ref="form">
                <v-col class="pa-0 mt-3" cols="12">
                    <v-text-field placeholder="Password" density="compact" v-model="password" variant="outlined"
                        :rules="passwordRule" @keyup="message = ''" v-bind:type="[showPassword ? 'text' : 'password']">
                        <span class="visionIcon">
                            <v-icon @click="showPassword = !showPassword">{{
                                showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                            }}</v-icon>
                        </span></v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12" align="center">
                    <p style="color: red;">{{ message }}</p>
                </v-col>
                <v-col cols="12" class="pa-0 mb-5 mt-2" align="center">
                    <v-btn color="indigo" block type="submit" size="large">Signup</v-btn>
                </v-col>
            </v-form>
        </v-card-text>
    </v-card>
    <Footer />
</template>

<script>
import Footer from './Footer.vue';
import UserModel from "@/api/service/user/UserService";
export default {
    components: {
        Footer
    },
    data() {
        return {
            message: null,
            showPassword: false,
            password: '',
            passwordRule: [(value) => !!value || "Password is Required."],
        }
    },
    created() {

    },
    methods: {
        async save() {
            let validate = await this.$refs.form.validate();
            if (validate.valid) {
                let loginData = JSON.parse(localStorage.getItem('login_details'))
                let email = localStorage.getItem('userEmail')
                await UserModel.authenticate({
                    email: email,
                    password: this.password,
                    appId: loginData && loginData.appId ? loginData.appId : this.$route.query.appId
                })
                    .then((response) => {
                        UserModel.updateAuthLog({
                            sessionId: loginData && loginData.sessionId ? loginData.sessionId : this.$route.query.sessionId,
                            loginSuccess: true
                        })
                        let $referrer = localStorage.getItem("ONEAPP_REFERRER");
                        localStorage.removeItem('userEmail')
                        localStorage.removeItem('login_details')
                        localStorage.removeItem('APP_ID')
                        localStorage.removeItem('ONEAPP_REFERRER')
                        window.location = `${$referrer}login?token=${response.token}`;

                    })
                    .catch((err) => {
                        if (err.response.data.message) {
                            this.message = "Invalid Email Or Password"
                        }
                    });
            }
        }
    }

}
</script>

<style></style>