import { createRouter, createWebHistory } from 'vue-router'
import Signup from './components/Signup.vue'
import Login from './components/Login.vue'
import VerifyOtp from './components/VerifyOtp.vue'
import ForgetPassword from './components/ForgetPassword.vue'
import newpassword from './components/resetPassword.vue'
import passwordreset from './components/passwordreset.vue'
import CheckEmail from './components/CheckEmail.vue'
import SignupVerification from './components/SignupVerification.vue'
const publicPages = ['/login', '/logout'];
const routes = [

  {
    path: '/signIn',
    component: Login,
  },
  {
    path: '',
    redirect:'/signIn' ,
  },
  {
    path: '/signup',
    component: Signup,
  },
  {
    path: '/otp',
    component: VerifyOtp,
  },
  {
    path: '/forgetpassword',
    component: ForgetPassword,
  },
  {
    path: '/resetPassword',
    component: newpassword,
  },
  {
    path: '/passwordreset',
    component: passwordreset,
  },
  {
    path: '/checkemail',
    component: CheckEmail,
  },
  {
    path: '/verification',
    component: SignupVerification,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to) => {
  const authRequired = !publicPages.includes(to.path);
  console.log(authRequired)
  console.log("REF", window.document.referrer)
  if (window.document.referrer.indexOf('/logout') > 0) {
    let $host = window.document.referrer
    let $loginHost = $host.substr(0, $host.indexOf('/logout')) + '/login'
    console.log($loginHost)
    localStorage.setItem('ONEAPP_REFERRER', $loginHost)
  } else {
    localStorage.setItem('ONEAPP_REFERRER', window.document.referrer)
  }
  // if (authRequired ) {
  //   if (window.document.referrer.indexOf('www.') > 0) {
  //     localStorage.setItem('ONEAPP_REFERRER', window.document.referrer)
  //     return next("/logout")
  //   }
  //   next("/login")

  // } else {
  //   next()
  // }
})

export default router