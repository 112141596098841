<template>
    <v-card class="card mt-5 pa-8">
        <v-row no-gutters>
            <v-col cols="12" align="center">
                <img src="../assets/image/passwordreset.svg" alt="forget" />
            </v-col>
            <v-col cols="12" align="center" class="LoginTitle mt-2 pa-0">
                Password reset
            </v-col>
            <v-col cols="12" align="center" class="textcontent mt-3">
                Your password has been successfully reset.Click below to login
            </v-col>
            <v-col class="pa-0 mt-4" cols="12" align="center">
                <v-btn block color="indigo" type="submit" size="large" to="/">Continue</v-btn>
            </v-col>
        </v-row>
        <v-col class="pa-0 mt-4" cols="12" align="center">
            <v-btn variant="plain" prepend-icon="mdi-arrow-left" type="submit" to="/">Back to log in</v-btn>
        </v-col>
    </v-card>
    <Footer />
</template>
<script>
import Footer from './Footer.vue';
export default {
    components: {
        Footer
    },
}
</script>
<style lang="scss"></style>