<template>
    <v-card class="card mt-7 pa-8">
        <v-col align="center" class="pa-0">
            <img src="../assets/image/message.svg" alt="message" />
        </v-col>
        <v-col class="LoginTitle pa-0 mt-3">Check your email</v-col>
        <v-col align="center" class="textcontent pa-2 mt-2">We sent a password reset link to <span
                style="color:#3C5AAA;text-transform:lowercase;">{{ $route.query.email }}</span>
        </v-col>
        <v-row no-gutters class="textFileCenter mt-1">
            <v-col align="center">
                Didn’t receive the email ?<v-btn variant="plain" @click="resend()" color="#3C5AAA">Click to
                    resend</v-btn>
            </v-col>
        </v-row>

        <div class="textFileCenter mt-3">
            <v-btn variant="plain" prepend-icon="mdi-arrow-left" to="/">Back to log in</v-btn>
        </div>
    </v-card>
    <Footer />
</template>

<script>
import Footer from './Footer.vue';
import ResetPasswordModel from '@/api/service/ResetPasswordService';
export default {
    components: {
        Footer
    },
    methods: {
        async resend() {
            var email = this.$route.query.email
            var host = location.origin
            await ResetPasswordModel.forgotPassword({ email: email, host: host + "/newpassword", appId: localStorage.getItem('APP_ID') })

        }
    }
}
</script>

<style lang="scss"></style>