<template>
    <v-form ref="form">
        <v-card class="card mt-7 pa-8">
            <v-row no-gutters>
                <v-col cols="12" align="center">
                    <img src="../assets/image/forget.svg" alt="forget" />
                </v-col>
                <v-col cols="12" align="center" class="LoginTitle mt-3 pa-0">Forget Password?</v-col>
                <v-col cols="12" align="center" class="textcontent mt-3">
                    No worries,we’ll send you reset instructions
                </v-col>
            </v-row>
            <v-col class="pa-0 mt-3">
                <v-text-field density="compact" type="email" v-model="email"
                    :rules="[rules.emailValidation, rules.useremail]" placeholder="Your Email"
                    append-inner-icon="mdi-account-outline" variant="outlined">
                </v-text-field>
            </v-col>
            <v-col class="pa-0 mt-2" cols="12" align="center">
                <v-btn block @click="sendEmail()" color="indigo" type="submit" size="large">Reset
                    Password</v-btn>
            </v-col>
            <v-col class="pa-0 mt-4" cols="12" align="center">
                <v-btn variant="plain" prepend-icon="mdi-arrow-left" type="submit" to="/">Back to log in</v-btn>
            </v-col>
        </v-card>
    </v-form>
    <Footer />
</template>

<script>
import ResetPasswordModel from '@/api/service/ResetPasswordService';
import Footer from './Footer.vue';
export default {
    components: {
        Footer
    },
    data() {
        return {
            email: "",
            host: "",
            rules: {
                emailValidation: value => !!value || "Email is Required.",
                useremail: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
        }
    },
    methods: {
        async sendEmail() {
            this.$refs.form.validate();
            if (this.email != "") {
                var host = location.origin
                this.$router.push({ path: '/checkemail', query: { email: this.email } })
                var res = await ResetPasswordModel.forgotPassword({ email: this.email, host: host, appId: localStorage.getItem('APP_ID') })
                console.log(res)



            }
        }
    },
}
</script>

<style lang="scss"></style>