<template>
    <div>
        <v-card>
            <v-card-title class="dialog-Title"> Cookie Policy
                <v-btn density="comfortable" @click="closeDialog" elevation="0" icon="mdi-close"
                    style="float: right;"></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                Website Name follows a standard procedure of using log files. These files log visitors when they visit
                websites. All
                hosting companies do this and a part of hosting services' analytics. The information collected by log files
                include
                internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp,
                referring/exit
                pages, and possibly the number of clicks. These are not linked to any information that is personally
                identifiable.
                The purpose of the information is for analyzing trends, administering the site, tracking users' movement on
                the
                website, and gathering demographic information.

                Cookies and Web Beacons
                Like any other website, Website Name uses ‘cookies'. These cookies are used to store information including
                visitors'
                preferences, and the pages on the website that the visitor accessed or visited. The information is used to
                optimize
                the users' experience by customizing our web page content based on visitors' browser type and/or other
                information.

                DoubleClick DART Cookie
                Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads
                to our
                site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors
                may
                choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at
                the
                following URL – https://policies.google.com/technologies/ads.

                Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below.
                Each of
                our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we
                hyperlinked to their Privacy Policies below.
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    methods: {
        closeDialog() {
            this.$emit('close')
        },
    }
}
</script>