<template>
    <v-card class="card mt-5 pa-8">
        <v-form @submit.prevent="createUser" ref="form">
            <v-row no-gutters dense>
                <v-col cols="12" class="LoginTitle pa-0">Sign up</v-col>
                <v-col cols="12" class="pa-0">
                    <v-text-field class="mt-4" @keyup="showExist = false" v-model="email" single-line density="compact"
                        placeholder="Your Email" type="email" append-inner-icon="mdi-account-outline"
                        :rules="[rules.userRule, rules.emailValidation]" variant="outlined">
                    </v-text-field>
                    <div style="color: red;margin: 5px;" v-if="showExist">Already Exist</div>
                </v-col>
                <!-- <v-col cols="12"><p style="color: red;">{{ message }}</p></v-col> -->
                <v-col class="pa-0 mt-2" cols="12" align="center">
                    <v-btn color="indigo" block type="submit" size="large">
                        <p v-if="!showLoader">Sign up</p>
                        <v-progress-circular v-else indeterminate></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- <v-row no-gutters class="mt-4">
            <v-col><v-divider class="border-opacity-100 mt-3" color="grey-lighten-1"></v-divider></v-col>
            <v-col align="center">OR</v-col>
            <v-col><v-divider class="border-opacity-100 mt-3" color="grey-lighten-1"></v-divider></v-col>
        </v-row>
        <v-row no-gutters>
            <v-col align="center" class="mt-4">
                <v-btn block color="red" variant="outlined" size="large">
                    <img class="mr-4" src="../assets/image/google.svg" alt="Google" />
                    Sign in with Google</v-btn>

            </v-col>
            <v-col align="center" class="mt-4 mb-2">
                <v-btn block color="black" variant="outlined" size="large">
                    <img class="pr-7" src="../assets/image/apple.svg" alt="Apple" />
                    Sign in with Apple</v-btn>
            </v-col>
        </v-row> -->
    </v-card>
    <Footer />
</template>
<script>
import DynamodbModel from "@/api/service/Dynamodb/DynamodbService";
import apiConfig from "@/api/config/config";
import Footer from './Footer.vue';
import moment from 'moment-timezone'
import axios from 'axios'
const querystring = require('querystring')

export default {
    components: {
        Footer
    },
    data() {
        return {
            message: null,
            email: '',
            showExist: false,
            showLoader: false,
            rules: {
                userRule: (value) => !!value || "Email is Required.",
            },
        }
    },
    created() {

    },
    methods: {
        async createUser() {
            let validation = await this.$refs.form.validate()
            if (validation.valid) {
                this.showLoader = true;
                let loginData = JSON.parse(localStorage.getItem('login_details'))
                let data = {
                    appId: loginData.appId,
                    email: this.email
                }
                await DynamodbModel.createUser(data).then(res => {
                    this.message = res.data.message
                    let $validDate = moment().format('YYYY-MM-DD HH:mm:ss')
                    if (moment($validDate).format('Z') != '+00:00') {
                        $validDate = moment($validDate).tz('utc').format('YYYY-MM-DD HH:mm:ss')
                    }
                    if (res.data && res.data.url) {
                        var headers = {
                            'Content-Type': 'application/json'
                        }
                        let $urldata = {
                            "email": this.email,
                            "valid_date": $validDate,
                            "appId": "348ba42f-78d4-4ffd-9370-f37de7a2a38c"

                        }

                        let url = `${apiConfig[res.data.url]}checkUsers?` + querystring.stringify($urldata);
                        let url1 = apiConfig[res.data.url] + 'createUsers'
                        axios.get(url, headers).then(userres => {
                            this.showLoader = false;
                            console.log(userres, userres.data.data.length, 'useeresssssssss')
                            console.log(moment().format('YYYY-MM-DD HH:mm:ss'), 'current dataaeeee')
                            if (userres.data && userres.data.data && userres.data.data.length == 0) {
                                let $validDate = moment().format('YYYY-MM-DD HH:mm:ss')
                                if (moment($validDate).format('Z') != '+00:00') {
                                    $validDate = moment($validDate).tz('utc').format('YYYY-MM-DD HH:mm:ss')
                                }
                                let $data = {
                                    appId: loginData.appId,
                                    email: this.email,
                                    valid_date: $validDate,
                                    uid: res.data.uid
                                }
                                if (res.data && res.data.message == 'Already Exist') {
                                    $data.sendEmail = false
                                }
                                else {
                                    $data.sendEmail = true
                                    $data.password = res.data.password
                                }
                                console.log($data, 'usercreateeeeeeeeeeeee', url1)
                                axios.post(url1, $data, headers)
                                    .then(response => {
                                        console.log(response)
                                        this.showLoader = false;
                                        if (res.data && res.data.message == 'Already Exist') {
                                            localStorage.setItem('userEmail', this.email)
                                            this.$router.push('/verification')
                                        }
                                        else {
                                            this.$router.push('/signIn')
                                        }
                                    }, error => {
                                        console.log(error)
                                        this.showLoader = false;

                                    });
                            }
                            else {
                                this.showExist = true
                                this.showLoader = false;
                            }
                        }, error => {
                            console.log(error)
                            this.showLoader = false;
                        })
                    }
                }, error => {
                    console.log(error)
                    this.showLoader = false;
                })

            }
        }
    }
}
</script>
<style lang="scss"></style>