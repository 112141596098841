<template>
  <img width="200" class="singlogo mt-3" :src="subTitle.logo" alt="logo" />
</template>

<script>
import { authStore } from '@/store';
export default {
  computed: {
    subTitle() {
      return authStore.getters.getAppDetail;
    }
  },
};
</script>
