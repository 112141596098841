import ApiService from '@/api/service/ApiService.js';
import apiConfig from '../config/config';

var $options = {}
var ResetPasswordService = {
    forgotPassword: function ($data) {
        return new Promise(function (resolve, reject) {
            $options.path = 'forgotPassword'
            $options.baseUrl = apiConfig.gatewayUrl
            $options.method = "POST"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
        })
    },
    resetPassword: function ($data) {
        return new Promise(function (resolve, reject) {
            $options.path = 'resetPassword'
            $options.baseUrl = apiConfig.gatewayUrl
            $options.method = "POST"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
        })
    }
}

export default ResetPasswordService
